<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="UkraineCrisis"></company-submenu>
      
      <div class="submenu-content">
        <div v-if="loading" v-html="item"></div>
      </div> 

  </div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref, onMounted } from 'vue'

export default {
  name: 'UkraineCrisis',
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref([])
    const loading = ref(false)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Ukraine Crisis", link: "UkraineCrisis"})

    onMounted(() => { 
      getAPI
      .get('/content/pages/66')
      .then(response => {       
        let content = response.data.body
        for (var i in content){
            item.value.push(content[i].value)
        }
        loading.value = true
        console.log(item.value)
      })
    })
    return { item,loading, breadcrumb_links }
  }
}
</script>

<style scoped>
.outer{
  padding: 100px 100px;
}
h2 {
    margin-bottom: 15px;
}
.h2 {
    font-size: 30px;
}
.h1, .h2, .h3, .h4, .h5, .h6{
    font-family: Segoe, Arial;
    font-weight: 500;
    line-height: 1.1;
    color: #555555;
    margin: 0;
    padding: 0;
}
</style>